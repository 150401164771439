import React from "react";
import { TransactionFinder, TransactionTable } from "./";

function Transactions({dataSource, urlTransactionHistory, dataSourceFinanciadores, dataSourceClientes, showCliente}) {
    const monthStart = new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString());
    const monthEnd = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString());
    const queryByDefault = [
        {
            field: "fechaInicial",
            operator: "greaterThan",
            value: monthStart
        },
        {
            field: "fechaFinal",
            operator: "lessThan",
            value: monthEnd
        }];

    const [gridRef, setGridRef] = React.useState();
    const defaultPageSettings = { currentPage: 1, pageSize: 20 };
    const [filterSettings, setFilterSettings] = React.useState({
        mode: 'OnEnter',
        type: 'FilterBar',
        ignoreAccent: true,
        showFilterBarStatus: false,
        columns: queryByDefault
    });
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {

        if (gridRef && dataSource !== null) {
            gridRef.dataSource = dataSource;
        }

    }, [gridRef, dataSource]);

    const filter = async (query) => {

        /// se agrega un campo random para traer data actualizada
        query.push({field:'fecha', operator: 'equal', value: new Date()});

        const filter = { ...filterSettings }


        filter.columns = query;

        setIsLoading(true);

        setFilterSettings(filter);
    };

    const clearFilter = async () => {
        await filter(queryByDefault);
    };

    const actionBegin = (pageEventArgs) => {
        if (pageEventArgs?.requestType === "filtering") {
            gridRef.goToPage(1);
        }
    }

    const beforeDataBound = (args) => {
        //TODO: no siempre se ejecuta esta parte donde el filtro de busqueda no cambia
        //setIsLoading(false);
        // console.log("beforeDataBound");
    }

    const actionComplete = (args) => {

        setIsLoading(false);

    }

    return (
        <div className="flex-grow w-full lg:flex">
            <div className="flex-1 min-w-0 xl:flex">
                <div className="xl:flex-shrink-0 xl:w-96">
                    <div className="h-full">
                        <TransactionFinder
                            filter={filter}
                            clearFilter={clearFilter}
                            isLoading={isLoading}
                            showCliente={showCliente}
                            dataSourceFinanciadores={dataSourceFinanciadores}
                            dataSourceClientes={dataSourceClientes}
                        />
                    </div>
                </div>

                <div className="lg:min-w-0 lg:flex-1">
                    <div className="h-full xl:ml-6 mt-6 xl:mt-0">
                        <TransactionTable
                            setGridRef={setGridRef}
                            pageSettings={defaultPageSettings}
                            filterSettings={filterSettings}
                            actionBegin={actionBegin}
                            beforeDataBound={beforeDataBound}
                            actionComplete={actionComplete}
                            showCliente={showCliente}
                            urlTransactionHistory={urlTransactionHistory}
                        />
                    </div>
                </div>
            </div>
        </div>);
}

export default Transactions;