import React from "react";
import { Download } from "react-feather";
import { ButtonIcon } from "../button";
import apiConfig from "../../data/config";
import DownloadFile from "../../services/downloadFile";

const WithdrawalButton = ({ ...props }) => {

    const handleButtonClick = (event) => {
        event.preventDefault();

        return DownloadFile(`${apiConfig.baseUrl}cliente/GetWithdrawal/${props.retiroID}/excel`,
            'GET',
            'Application/msexcel',
            'reporte-ventas.xlsx');
    };

    return (
        <>
            {props.clienteID === 2 &&
                <ButtonIcon
                    onClick={handleButtonClick}
                    title={"Descargar"}
                >
                    <Download className="h-3 w-3 text-gray-400" aria-hidden="true" />
                </ButtonIcon>
            }
        </>
    );
}

export { WithdrawalButton }